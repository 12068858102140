import { SET_NEW_USER_STATUS, SET_CARDNUMBER, SET_CARDLINK } from "../types";

const initialState = {
  newUser: true,
  cardNumber: "",
  cardLink: ""
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_USER_STATUS:
      return {
        ...state,
        newUser: action.payload,
      };
    case SET_CARDNUMBER:
      return {
        ...state,
        cardNumber: action.payload,
      };
    case SET_CARDLINK: 
    return {
        ...state,
        cardLink: action.payload
    }
    default:
      return state;
  }
}

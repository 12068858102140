import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOG_OUT,
} from "../types";

const initialState = {
  requesting: false,
  isLoggedIn: false,
  user: {},
  error: "",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        requesting: false,
        isLoggedIn: true,
        user: action.payload,
        error: "",
      };
    case LOGIN_FAILURE:
      return {
        requesting: false,
        isLoggedIn: false,
        user: {},
        error: action.payload,
      };
    case LOG_OUT:
      return initialState;
    case REGISTER_SUCCESS:
      return {
        requesting: false,
        isLoggedIn: true,
        user: action.payload,
        error: "",
      };
    case REGISTER_FAILURE:
      return {
        requesting: false,
        isLoggedIn: false,
        user: {},
        error: action.payload,
      };
    default:
      return state;
  }
}

import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Router from "./router/Router";
import StateProvider from "./libs/context";

const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StateProvider>
        <Router />
      </StateProvider>
      <Toaster />
    </QueryClientProvider>
  );
};

export default App;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * When the pathname changes, scroll to the top of the page.
 * @returns null
 */
const useScrollToTop = () => {
    let {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default useScrollToTop;
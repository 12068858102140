import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Description from "./Description";
import { QuantitySelector } from "./QuantitySelector";
import { useItemContext } from "../components/storeItem/StoreContext";

export const ProductDetails = ({ item, quantity, onQuantityChange }) => {
  const { cartItems, setCartItems, setIsItemAdded } = useItemContext();
  const country = localStorage.getItem("country");

  const handleAddToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === item.id) {
          return { ...cartItem, quantity: item.quantity };
        }
        return cartItem;
      });
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, item]);
    }
    setIsItemAdded(true);
  };

  return (
    <div className="storeItem__info lg:!pr-28 !text-[#D7D7D7]">
      <h2 className="storeItem__info-name">
        {item.name === "AXAO Card" ? "Digital Card" : item.name}
      </h2>
      <p className="storeItem__info-description !-mt-3 !font-extralight !text-sm !capitalize">
        {item.desc}
      </p>
      <QuantitySelector
        quantity={quantity}
        onQuantityChange={onQuantityChange}
      />
      <p className="storeItem__product-about !font-thin !text-lg">About</p>
      {typeof item.itemInfo === "function" ? (
        item.itemInfo()
      ) : (
        <p className="storeItem__product-info !-mt-4">{item.itemInfo}</p>
      )}
      {/* <p className="storeItem__product-info !-mt-4">{item.itemInfo}</p> */}
      <div className="storeItem__cart-container">
        <p className="storeItem__price !font-extralight !tracking-tight">
          {country === "Nigeria"
            ? `₦${item.price.toLocaleString()}`
            : `$${item.foreign_price}`}
        </p>
        <div className="storeItem__cart-wrapper">
          <Link to="/products/cart">
            <button
              className="storeItem__cart cu"
              disabled={quantity < 1}
              onClick={() => {
                handleAddToCart(item);
              }}
            >
              Add to Cart
            </button>
          </Link>
        </div>
      </div>
      <Description item={item} />
    </div>
  );
};

ProductDetails.propTypes = {
  item: PropTypes.any,
  quantity: PropTypes.any,
  onQuantityChange: PropTypes.any,
};

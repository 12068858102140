export const SET_NEW_USER_STATUS = "SET_NEW_USER_STATUS"

//Authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const LOG_OUT = "LOG_OUT";


//Profile
export const SET_CARDNUMBER = "SET_CARDNUMBER";
export const SET_CARDLINK = "SET_CARDLINK";
import React from "react";
import PropTypes from 'prop-types'
import { IoIosArrowDown } from "react-icons/io";

export const QuantitySelector = ({ quantity=1, onQuantityChange }) => {
  return(
  <div className="storeItem__quantity !my-8 !text-[#D7D7D7]">
    <p className=" !font-extralight text-sm">Quantity</p>
    <div className="storeItem__quantity-amount !p-0 !px-3">
      <input
        type="number"
        value={parseInt(quantity)}
        onChange={(e) => onQuantityChange(parseInt(e.target.value))}
        className="storeItem__quantity-input"
      />
      <div className="storeItem__quantity-img__container">
        <div className="flex flex-col justify-center">
          <IoIosArrowDown
            className="-rotate-180 cursor-pointer"
            onClick={() => onQuantityChange(quantity + 1)}
          />
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={() => onQuantityChange(quantity > 0 ? quantity - 1 : 0)}
          />
        </div>
      </div>
    </div>
  </div>
)}

QuantitySelector.propTypes = {
  quantity: PropTypes.any,
  onQuantityChange: PropTypes.any,
};

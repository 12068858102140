import { css } from "styled-components";

export const LandingPagePadding = css`
   padding: 0px 20px;
   @media screen and (min-width: 768px){
       padding: 0px 70px;
   }

   @media screen and (min-width: 1500px) {
       padding: 0px;
   }
`;

import jwtDecode from "jwt-decode";

/**
 * It takes a token as an argument, decodes it, and returns the decoded token
 * @param token - The token to decode.
 * @returns The decoded token.
 */
const decodeToken = (token) => {
  if (!token) return "";
  return jwtDecode(token);
};

export default decodeToken;

import React from "react";
import PropTypes from "prop-types";

function Description({item}) {
  return (
    <div className="mt-20 lg:!pr-20">
      {/* Description */}
      <div>
        <p className="storeItem__product-about">DESCRIPTION</p>

        {typeof item.description === "function" ? (
          item.description()
        ) : (
          <p className="storeItem__product-info">
            Features interactive elements like QR codes, clickable links,
            embedded maps, and call-to-action buttons. Technical specs include
            formats (vCard, PDF, HTML), optimized file size, responsive design,
            and customizable templates. Ensures privacy and security with data
            protection and access control.
          </p>
        )}
      </div>
      {/* Compatibility */}
      <div>
        <p className="storeItem__product-about">COMPATIBILITY</p>
        {typeof item.compatibility === "function" ? (
          item.compatibility()
        ) : (
          <p className="storeItem__product-info">
            Accessible on smartphones, tablets, and computers; integrates with
            CRM systems and email signatures.
          </p>
        )}
      </div>
      {/* Shipping and Returns */}
      <div>
        <p className="storeItem__product-about">SHIPPINGS AND RETURNS</p>
        <p className="storeItem__product-info">
          {item.shipping
            ? item.shipping
            : "Costs are the customer&apos; responsibility unless the item is defective or there was a shipping error."}
        </p>
      </div>
      {/* Refund n Processings */}
      <div>
        <p className="storeItem__product-about uppercase">Refund Processings</p>
        <p className="storeItem__product-info">
          {item.refundProcessing
            ? item.refundProcessing
            : "Refund Processing: Refunds are issued to the original payment method within 7-10 business days of receiving the returned item."}
        </p>
      </div>
      {/* Exchangess */}
      <div>
        <p className="storeItem__product-about uppercase">exchanges</p>
        <p className="storeItem__product-info">
          {item.exchanges
            ? item.exchanges
            : "Accepted for items of equal value; return the original item and place a new order for different sizes or colors."}
        </p>
      </div>
    </div>
  );
}

Description.propTypes = {
    item:PropTypes.object
};

export default Description;

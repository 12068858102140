import React from "react";
import BoldPro from "./product_image/Boldpro.svg";
import KeyFinder from "./product_image/keyFinder1.svg";
import pill from "./product_image/BudCase.jpg";
import smartcardRed from "./product_image/smartcard.red.png";
import smartcardYellow from "./product_image/Business card.yellow.png";
import smartcardPurple from "./product_image/Business card.purple.png";
import smartcardBlue from "./product_image/Business card.blue.png";
import L1 from "./product_image/onyx-pantherL1.jpg";
import watch from './product_image/Voyager.png';
import visio from './product_image/Visio Pro.png';
import L2 from './product_image/Onyx Panther L1.png';
import storm from './product_image/Storm.png';
import cable from './product_image/C to Lightening.png';
import ganwatt from './product_image/Ganwatt.png';
import card from './product_image/AXAO Phanthom.png';
import storm2 from './product_image/StormMini.png';
import storm3 from './product_image/Storm3.png';
import leather from './product_image/leatherWallet.jpg'
import { discount } from "../../../constants/discount";


export const allProduct = [
  {
    id: 1,
    preorder: true,
    hot: false,
    sold:false,
    name: "Onyx Panther 2",
    ref: 'onyx-pantherL1.jpg',
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cd7d85eb9585ab207c0ca51ceaff308aac52713c41b7f2a81b66e9ebaf85eedb78c0dba5055de26dc3f9b17b48735b9e2',
    price: discount(30000),
    actual_price: 30000,
    foreign_price:discount(40),
    actual_foreign_price:40,
    new: false,
    colors: "black",
    category: "bud case",
    itemInfo: `
          Hd binaural call 
          High fidelity sound
          Voice assistant
          Noise reduction
          Touch control
        `,
    description: null,
    compatibility: null,
    desc: "dark grey color",
    images: L1,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 2,
    preorder: false,
    hot: false,
    sold:true,
    name: "Bold Pro",
    ref: 'Boldpro.svg',
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c0454aa1bb5e4d02eca12bb3835b1ab50affaa9d55b98e833fbfea7d37b40f6eb814d567558ee932c268380bcbaffe246',
    price: discount(30000),
    actual_price:30000,
    foreign_price:discount(30),
    actual_foreign_price:30,
    new: true,
    colors: "black",
    category: "bud-case",
    itemInfo: `
      Hd binaural call 
      High fidelity sound
      Voice assistant
      Noise reduction
      Touch control
    `,
    description: null,
    compatibility: null,
    desc: "black with white stripe",
    images: BoldPro,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 3,
    preorder: false,
    hot: true,
    sold:false,
    name: "Onyx Panther",
    ref: 'BudCase.jpg',
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cc711bdd1de6f3269ae9200917bd8851904116b4c06616a727bd1f43d03e98db3171e57a493fd7da98e3dfc4584259ddd',
    price: discount(20000),
    actual_price:20000,
    actual_foreign_price:30,
    foreign_price:discount(30),
    new: false,
    colors: "black",
    category: "key finder",
    itemInfo: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Audio Quality: High-fidelity, lossless</li>
        <li>Codec Support: AAC, aptX, aptXC Adaptive, LDAC</li>
        <li>Driver Size: 12mm</li>
        <li>Frequency Response: 20Hz - 20KHz</li>
        <li>
          Controls:
          <ul className="!list-disc ml-3">
            <li>
              Left Earbud: Single Tap (Pause/Play), Double Tap (Repeat/Skip
              Backward), Triple Tap (Voice Control), Hold (Decrease Volume),
              Single Tap (End Call)
            </li>
            <li>
              Right Earbud: Single Tap (Pause/Play), Double Tap (Skip Forward),
              Triple Tap (Voice Control), Hold (Increase Volume), Single Tap
              (Receive Call)
            </li>
          </ul>
        </li>
        <li>
          Features:
          <ul className="!list-disc ml-3">
            <li>Voice Assistant Integration</li>
            <li>Environmental Noise Cancelling</li>
            <li>Transparency Mode (some models)</li>
            <li>Adaptable EQ</li>
          </ul>
        </li>
      </ul>
    ),
    description: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Bluetooth Version: 5.2</li>
        <li>
          Battery Life: ~ 5 hours (earbuds), up to 30 hours additioonal
          (charging case), ~ 35 hours total
        </li>
        <li>
          Charging Time: 30 minutesa to 1 hour (earbuds), 2 - 3 hours (case), ~
          35 hours
        </li>
        <li>Water Resistance: IP54</li>
        <li>
          Microphone: Dual-microphone with beamforming, ENC, HD binaural calls
        </li>
      </ul>
    ),
    compatibility: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Dimensions: Compact and pocket-sized</li>
        <li>Devices:iOS, Android, other Bluetooth devices</li>
        <li>Packaging: Charging cable (USB-A to USB-C), user manual</li>
      </ul>
    ),
    desc: "black with white stripe",
    images: pill,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 4,
    preorder: false,
    hot: false,
    sold:false,
    name: "AXAO Hive",
    ref: 'keyFinder1.svg',
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c365df1bcc1ecb2e54fd057877ee10e8e60c909460e14139493cc6b61a9aa2e990c8cf592487d972479f55ca55594a95f',
    price: discount(20000),
    foreign_price:discount(20),
    actual_price: 20000,
actual_foreign_price:20,
    new: false,
    colors: "black",
    category: "ear piece",
    itemInfo: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Compatibility: Works with Apple&apos;s Find My network.</li>
        <li>Design: Compact, lightweight, with a keyring attachment.</li>
        <li>Battery Life: 6-12 months with replaceable or rechargeable battery.</li>
      </ul>
    ),
    description: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Connectivity: Bluetooth Low Energy (BLE) and Apple Find My network for tracking.</li>
        <li>
        Tracking Range: Up to 100 feet (30 meters) via Bluetooth; broader range through Find My network.
        </li>
        <li>
        Features: Integrates with the Find My app, emits sound alerts, provides location history, and supports Lost Mode.
        </li>
        <li>Durability: Water-resistant or splash-proof.</li>
      </ul>
    ),
    compatibility: () => (
      <ul className="!list-disc ml-3 -mt-5 font-thin tracking-wide text-sm text-white">
        <li>Setup: Pairs with iOS devices via Bluetooth and the Find My app.</li>
        <li>Devices: Compatible with any Apple device using the Find My network.</li>
      </ul>
    ),
    desc: "dark grey color",
    images: KeyFinder,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 16,
    preorder: true,
    hot: false,
    sold:false,
    name: "Storm Mini Pro ANC",
    ref: "StormMini.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cb4d1b4f8bf0c0117a724f87ee6abad93ab8de78929312b9d26f9e27e22dc1d626964d37c04a2e752eec2fb8da79b26ed',
    new: false,
    price: discount(40000),
    foreign_price:discount(40),
    actual_price: 40000,
    actual_foreign_price:40,
    colors: "#000",
    category: "Storm ANC",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Black",
    images: storm2,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 17,
    preorder: true,
    hot: false,
    sold:false,
    name: "Storm Mini Pro ENC",
    ref: "Storm3.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c4aa39132e584985a82f106ed1695174b785745125dd20195e178bdbd828c6580adbd8c14b0a6626c469935b107037347',
    new: false,
    price: discount(40000),
    foreign_price: discount(40),
    actual_price: 40000,
    actual_foreign_price:40,
    colors: "#000",
    category: "Storm ENC",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Black",
    images: storm3,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 5,
    preorder: false,
    hot: true,
    sold:false,
    name: "AXAO Card",
    ref: 'axaoCard.svg',
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c0367df3a7680a994bffaa09530d176787b3629528ae4bbb1cdca44390bdcc80ac75836c64fd5a77518fff39ec9f2dda7',
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "red",
    category: "smartcard",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Red Card",
    images: smartcardRed,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 6,
    preorder: false,
    hot: true,
    sold:false,
    name: "AXAO Card",
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "yellow",
    ref: "Business card.yellow.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c7384b98a574552f2f5bab2f2fec7afe15ab6934c7483bcecbdb25dcc0eb011a32c6fe081b2bd6f8620e9f7b9d6d31f5c',
    category: "smartcard",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Yellow Card",
    images: smartcardYellow,
    quantity: 1,
    path: "products/store",
  },
  {
    id: 7,
    preorder: false,
    hot: true,
    sold:false,
    name: "AXAO Card",
    ref: "Business card.purple.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cc32dc4ce538fcc66c9bb50ce77c4504fb4537179ae2ec65e04f3a8d7ec91bbb0888a841f314b28d7d5c9ee5266dc9a49', 
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "purple",
    category: "smartcard",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Purple Card",
    images: smartcardPurple,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 8,
    preorder: false,
    hot: true,
    sold:false,
    name: "AXAO Card",
    ref: "Business card.blue.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cd3aba731b3f7d3aa4244c5888d7653a3dca85e3e8f0bb31273a5eba96d10e6f367a07de7a5ef398b47eaef7de2286d2a',
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "#33ABC6",
    category: "smartcard",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Blue Card",
    images: smartcardBlue,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 9,
    preorder: true,
    hot: false,
    sold:false,
    name: "Voyager",
    ref: "Voyager.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0ca18604958f8e060bbdc013f2c5e0f5b26135873c6412b4658c31597d44bed03d9c068670ef3107e91f7f3ff0838236a2',
    new: false,
    price: discount(220000),
    foreign_price: discount(150),
    actual_price: 220000,
    actual_foreign_price:150,
    colors: "black",
    category: "watch",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Black Smart Watch",
    images: watch,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 10,
    preorder: true,
    hot: false,
    sold:false,
    name: "Onyx Panther L1",
    ref: "Onyx Panther L1.png",
    url:'  https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c34be6af94582c88bad5b328cd29c7c11e208df8f484297d9f9c86f3af292087e93b178542f364e7ff1c42dcf66fcd4ab',
    new: false,
    price: discount(30000),
    foreign_price: discount(30),
    actual_price: 30000,
    actual_foreign_price:30,
    colors: "white",
    category: "bud",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "White ",
    images: L2,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 11,
    preorder: false,
    hot: false,
    sold:false,
    name: "Storm",
    ref: "Storm.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c9a01afa9f53ebe33a4c05bfd8860a334e72b9259042d9fbf628ca0ee7e748707b060d0633f33f5f74be40ecbe2f10fc8',
    new: false,
    price: discount(150000),
    foreign_price: discount(100),
    actual_price: 150000,
    actual_foreign_price:100,
    colors: "#ff6116",
    category: "slimcase bud",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Orange Open Earbuds",
    images: storm,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 12,
    preorder: false,
    sold:true,
    hot: false,
    name: "C to Ligtening",
    ref: "C%20to%20Lightening.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c18b492e6c6b34d6b03abc484563b88ca81c3e470b6243b569f86345bed1796b1812c511566c00ec23ad97a553b3ee63f',
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "black",
    category: "charging cable",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Dark Charging Cable",
    images: cable,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 13,
    preorder: false,
    sold:true,
    hot: false,
    name: "Ganwatt",
    ref:"Ganwatt.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cb0d3b5f0b9e65d75b4aa5e9fde26cb33d90c7c139fb223485f41fbe25d00350e175c5c7e7fbda4bd57f40b7fa6df18f4',
    new: false,
    price: discount(22000),
    foreign_price: discount(20),
    actual_price: 22000,
    actual_foreign_price:20,
    colors: "nude",
    category: "charger",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "dark Charger",
    images: ganwatt,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 14,
    preorder: false,
    sold:false,
    hot: false,
    name: "Visio Pro",
    ref:"Visio Pro.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cbe0090d5ea4f3630d38738dc7dd23b32adabf1e85faa60eae6cd2eaaad8ea83a01f5beff6d8f96ed6207fe5c8e12a9e3',
    new: false,
    price: discount(150000),
    foreign_price: discount(100),
    actual_price: 150000,
    actual_foreign_price:100,
    colors: "black",
    category: "glasses",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Smart Glass",
    images: visio,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 15,
    preorder: false,
    sold:false,
    hot: true,
    name: "AXAO Phanthom",
    ref:"AXAO Phanthom.png",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0c4990b47fb9425712b8870d3cce492fe60f2f96527a2e0786064f4af1411bc547e48e2d89a88005d1bddc4b08904a4520',
    new: false,
    price: discount(12000),
    foreign_price: discount(10),
    actual_price: 12000,
    actual_foreign_price:10,
    colors: "black",
    category: "card",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "Digital Card",
    images: card,
    quantity: 1,
    path: "/products/store",
  },
  {
    id: 16,
    preorder: true,
    sold:false,
    hot: false,
    name: "MagSafe Wallet",
    ref:"leatherWallet.jpg",
    url:'https://imagekit.io/public/share/Adetimmy/a5b1060c528e4b21c837c651352cd98bdd33546b7b8af4d0ef01acace28b5a0cc192a7b8d9c9a34654b2c1e383e05c09e23d86a0f5a910af9491e239f71a4764fb53b514ae5113a368508dd125637946',
    new: false,
    price: discount(20000),
    foreign_price: discount(20),
    actual_price: 20000,
    actual_foreign_price:20,
    colors: "#835300",
    category: "wallet",
    itemInfo: `Highly customizable to include (name, job title, company, phone, email), design elements (logo, brand colors, background), and additional details (website URL, social media links, physical address, business hours).`,
    description: `Features interactive elements like QR codes, clickable links, embedded maps, and call-to-action buttons. Technical specs include formats (vCard, PDF, HTML), optimized file size, responsive design, and customizable templates. Ensures privacy and security with data protection and access control.`,
    compatibility: `Accessible on smartphones, tablets, and computers; integrates with CRM systems and email signatures.`,
    desc: "NFC Wallet",
    images: leather,
    quantity: 1,
    path: "/products/store",
  },
];

export const filteredProducts = allProduct.reduce(
  (acc, product) => {
    const { category } = product;

    // If the category hasn't been added yet, add the product to the result array
    if (!acc.categories.has(category)) {
      acc.filtered.push(product);
      acc.categories.add(category); // Mark this category as added
    }

    return acc;
  },
  { filtered: [], categories: new Set() }
).filtered;

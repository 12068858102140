import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as AppRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Main from "../layout/main";
import CardActivation from "../layout/card";
import { RequireCardAuth } from "./CardAuthWrapper";
import { CardLoginSignupWrapper } from "./CardLoginSignupWrapper";
import FallBack from "./FallBack";
import RedirectToValidateProduct from "../screens/landingpage/validateproduct/Redirect";
import { StoreItemContext } from "../screens/landingpage/productStore/components/storeItem/StoreContext";
import Product from "../screens/landingpage/productStore/productDisplay";

// Lazy-loaded components with prefetching
const Wallet = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/wallet"
  )
);
const Technology = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/technology"
  )
);
const Motivation = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/motivation"
  )
);
const Store = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore"
  )
);
const StoreCart = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore/Cart"
  )
);
const WalletPage = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/walletPage"
  )
);
const Buds = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/Buds"
  )
);
const MainPage = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/mainPage"
  )
);
const AccountDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/productStore/accountDetails"
  )
);
const Shipping = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/shipping"
  )
);
const Cart = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/cart"
  )
);
const PaymentMethod = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/checkout/paymentmethod"
  )
);
const PrivacyPolicy = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/privacypolicy"
  )
);
const CardProfile = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/cardprofile"
  )
);
const GettingStarted = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/gettingstarted"
  )
);
const LoginCard = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/login"
  )
);
const ResetPassword = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/resetpassword"
  )
);
const VerifyEmail = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/verifyemail"
  )
);
const ProfilePicture = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/profilepicture"
  )
);
const ValidateCardToken = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/validatecardtoken"
  )
);
const Onyx = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/landingpage/Buds/oldbud"
  )
);
const AboutMe = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/addlinks"
  )
);
const SuccessfulActivation = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/success"
  )
);
const Connections = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/connections"
  )
);
const UpdatePassword = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/updatepassword"
  )
);
const Security = lazy(() =>
  import(
    /* webpackPrefetch: true */
    "../screens/dashboard/activatecard/security"
  )
);

// Main Router Component
const Router = () => {
  return (
    <AppRouter>
      <StoreItemContext>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route
              index
              element={
                <Suspense fallback={<FallBack />}>
                  <Wallet />
                </Suspense>
              }
            />
            <Route path="faq" element={<RedirectToValidateProduct />} />
            <Route
              path="products/wallet"
              element={
                <Suspense fallback={<FallBack />}>
                  <WalletPage />
                </Suspense>
              }
            />
            <Route
              path="products/store"
              element={
                <Suspense fallback={<FallBack />}>
                  <Store />
                </Suspense>
              }
            >
              <Route
                path=":id"
                element={
                  <Suspense fallback={<FallBack />}>
                    <Product />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="products/cart"
              element={
                <Suspense fallback={<FallBack />}>
                  <StoreCart />
                </Suspense>
              }
            />
            <Route
              path="products/buds"
              element={
                <Suspense fallback={<FallBack />}>
                  <Buds />
                </Suspense>
              }
            >
              <Route
                path="bud-display"
                element={<Suspense fallback={<FallBack />}></Suspense>}
              />
            </Route>
            <Route
              path="products/buds/onyx-panther"
              element={
                <Suspense fallback={<FallBack />}>
                  <Onyx />
                </Suspense>
              }
            />
            <Route
              path="products/cart/account-details"
              element={
                <Suspense fallback={<FallBack />}>
                  <AccountDetails />
                </Suspense>
              }
            />
            <Route
              path="Homepage"
              element={
                <Suspense fallback={<FallBack />}>
                  <MainPage />
                </Suspense>
              }
            />
            {/* Store ends */}
            <Route
              path="technology"
              element={
                <Suspense fallback={<FallBack />}>
                  <Technology />
                </Suspense>
              }
            />
            <Route
              path="motivation"
              element={
                <Suspense fallback={<FallBack />}>
                  <Motivation />
                </Suspense>
              }
            />
            <Route
              path="checkout/cart"
              element={
                <Suspense fallback={<FallBack />}>
                  <Cart />
                </Suspense>
              }
            />
            <Route
              path="checkout/shipping"
              element={
                <Suspense fallback={<FallBack />}>
                  <Shipping />
                </Suspense>
              }
            />
            <Route
              path="checkout/paymentmethod"
              element={
                <Suspense fallback={<FallBack />}>
                  <PaymentMethod />
                </Suspense>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <Suspense fallback={<FallBack />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
          </Route>

          {/* Account Authentication Routes */}
          <Route
            path="activatecard"
            element={
              <Suspense fallback={<FallBack />}>
                <RequireCardAuth>
                  <GettingStarted />
                </RequireCardAuth>
              </Suspense>
            }
          >
            <Route
              path="login"
              element={
                <Suspense fallback={<FallBack />}>
                  <LoginCard />
                </Suspense>
              }
            />
            <Route
              path="reset-password"
              element={
                <Suspense fallback={<FallBack />}>
                  <ResetPassword />
                </Suspense>
              }
            />
            <Route
              path="verify-email"
              element={
                <Suspense fallback={<FallBack />}>
                  <VerifyEmail />
                </Suspense>
              }
            />
            <Route
              path="profile-picture"
              element={
                <Suspense fallback={<FallBack />}>
                  <ProfilePicture />
                </Suspense>
              }
            />
            <Route
              path="validate-card-token"
              element={
                <Suspense fallback={<FallBack />}>
                  <ValidateCardToken />
                </Suspense>
              }
            />
            <Route
              path="addlinks"
              element={
                <Suspense fallback={<FallBack />}>
                  <AboutMe />
                </Suspense>
              }
            />
            <Route
              path="success"
              element={
                <Suspense fallback={<FallBack />}>
                  <SuccessfulActivation />
                </Suspense>
              }
            />
            <Route
              path="connections"
              element={
                <Suspense fallback={<FallBack />}>
                  <Connections />
                </Suspense>
              }
            />
            <Route
              path="update-password"
              element={
                <Suspense fallback={<FallBack />}>
                  <UpdatePassword />
                </Suspense>
              }
            />
            <Route
              path="security"
              element={
                <Suspense fallback={<FallBack />}>
                  <Security />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </StoreItemContext>
    </AppRouter>
  );
};

export default Router;

import React from "react";
import PropTypes from "prop-types";

export function ArrowLeft(props) {
  return (
    <svg
      {...props}
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 4.22542C32.1255 4.22542 40.3334 12.4333 40.3334 22.5588C40.3334 32.6843 32.1255 40.8921 22 40.8921C11.8745 40.8921 3.66669 32.6843 3.66669 22.5588C3.66668 12.4333 11.8745 4.22542 22 4.22542Z"
        stroke="#F7F7F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.4">
        <path
          d="M28.4167 22.5588L17.4167 22.5588"
          stroke="#F7F7F7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0834 28.0588L15.5834 22.5588L21.0834 17.0588"
          stroke="#F7F7F7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

ArrowLeft.propTypes = {
  props: PropTypes.any,
};

import React from "react";
import styled from "styled-components";

const FallBack = () => {

    return (
        <Container>
            <div className="form-loading"></div>
        </Container>
    )
}

const Container = styled.div`
height: 80vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export default FallBack;
export const data = [
  {
    title: "SHOP",
    links: [
      {
        title: "Earbuds",
        link: "",
      },
      {
        title: "Chargers",
        link: "",
      },
      {
        title: "smart business card",
        link: "",
      },
      // {
      //   title: "TV & Home Theater",
      //   link: "",
      // },
      // {
      //   title: "Computing",
      //   link: "",
      // },
    ],
  },
  {
    title: "SUPPORT",
    links: [
      {
        title: "Contact Us",
        link: "",
      },
      {
        title: "Product Support",
        link: "",
      },
      {
        title: "Order Support",
        link: "",
      },
      {
        title: "Your Account",
        link: "",
      },
      {
        title: "Register Your Product",
        link: "",
      },
    ],
  },
  {
    title: "OFFERS",
    links: [
      {
        title: "Offers",
        link: "",
      },
      {
        title: "AXAO Rewards",
        link: "",
      },
      {
        title: "Student & Educator Discounts",
        link: "",
      },
      {
        title: "Government Discounts",
        link: "",
      },
      {
        title: "Employee Discounts",
        link: "",
      },
      {
        title: "First Responder Discounts",
        link: "",
      },
      {
        title: "Business Discounts",
        link: "",
      },
    ],
  },

  {
    title: "ABOUT US",
    links: [
      {
        title: "Our Business",
        link: "",
      },
      {
        title: "Brand Identity",
        link: "",
      },
      {
        title: "Careers",
        link: "",
      },
      {
        title: "Investor Relations",
        link: "",
      },
      {
        title: "News Room",
        link: "",
      },
      {
        title: "Corporate Citizenship",
        link: "",
      },
      {
        title: "Digital Responsibility",
        link: "",
      },
    ],
  },
];

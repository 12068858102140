import React from "react";
import PropTypes from 'prop-types'

export default function ChatIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6239 16.2893C21.9176 16.5537 22.3855 16.3442 22.384 15.949L22.3681 11.9437V5.47237C22.3681 4.28102 21.295 3.31525 19.9713 3.31525H8.78621C7.46249 3.31525 6.3894 4.28102 6.3894 5.47237V8.43852H12.9908C14.4999 8.43852 15.7232 9.66184 15.7232 11.1709V14.1009H19.1923L21.6239 16.2893Z"
        fill="currentColor"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42676 16.1231V11.8538C2.42676 10.9107 3.27628 10.1461 4.32423 10.1461H11.9141C12.9621 10.1461 13.8116 10.9107 13.8116 11.8538V16.1231C13.8116 17.0663 12.9621 17.8309 11.9141 17.8309H4.43403L3.20418 18.8903C2.909 19.1446 2.45157 18.9348 2.45157 18.5453V16.4C2.43525 16.3099 2.42676 16.2174 2.42676 16.1231ZM6.23576 13.1346C6.23576 12.8988 6.4269 12.7076 6.66269 12.7076H11.5012C11.737 12.7076 11.9282 12.8988 11.9282 13.1346C11.9282 13.3704 11.737 13.5615 11.5012 13.5615H6.66269C6.4269 13.5615 6.23576 13.3704 6.23576 13.1346ZM9.5089 14.4154C9.27311 14.4154 9.08197 14.6065 9.08197 14.8423C9.08197 15.0781 9.27311 15.2692 9.5089 15.2692H11.5012C11.737 15.2692 11.9282 15.0781 11.9282 14.8423C11.9282 14.6065 11.737 14.4154 11.5012 14.4154H9.5089Z"
        fill="currentColor"
      />
    </svg>
  );
}
ChatIcon.prototype = {
props: PropTypes.any
}
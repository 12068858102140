import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const StateContext = createContext(null);

export default function StateProvider({ children }) {
  const [authentication, setAuthentication] = useState(false);

  return (
    <StateContext.Provider value={{ authentication, setAuthentication }}>
      {children}
    </StateContext.Provider>
  );
}

StateProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

export const useStateContext = () => useContext(StateContext);


import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import logoutIcon from "../../assets/icons/logoutIcon.svg";
import { logoutUser } from "../../redux/actions/authAction";
import useScrollToTop from "../../utils/useScrollToTop";
import moreicon from "../../assets/icons/moreIcon.svg";
import { useStateContext } from "../../libs/context";
import { Tooltip } from "@mui/material";

const CardActivation = () => {
  useScrollToTop();
  const dispatch = useDispatch();
  const authentication = localStorage.getItem('axao');
  // const handleLogout = () => dispatch(logoutUser());
  // const user = useSelector((state) => state.auth.user);
  const handleLogout = () => localStorage.removeItem('axao');
  return (
    <>
      <Sidebar />
      <Content>
        {/* <Tooltip arrow title="Sign Out"> */}
          {authentication && (
            <div
              onClick={handleLogout}
              className="layout__card-logout max-md:hidden"
            >
              <img src={logoutIcon} alt="" />
            </div>
          )}
        {/* </Tooltip> */}
        <Outlet />
      </Content>
    </>
  );
};

const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden !important;
  background-color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  margin-top: 47px;
  margin-bottom:50px;
  z-index: 1;

  .layout__card-logout {
    display: none;
  }

  @media screen and (min-width: 768px) {
    margin-left: 227px;
    width: calc(100% - 227px);
    padding-left: 44px;
    padding-right: 44px;
    margin-top: 0px;
    position: relative;
    .layout__card-logout {
      position: absolute;
      right: 30px;
      top: 31px;
      display: block;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 50px;
  }
`;

export default CardActivation;

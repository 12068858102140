import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types"

const Sidebar = ({setSidebarOpen}) => {
  return (
    <Background onClick={() => setSidebarOpen(false)}>
      <Container>Side bar</Container>;
    </Background>
  );
};


Sidebar.propTypes = {
    setSidebarOpen: PropTypes.func 
}

const Container = styled.div`
  width: 250px;
  height: 100%;
  background-color: var(--whiteColor);
  min-height: 100vh;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 10011;
  /* display: none; */


  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  z-index: 10011;
`;

export default Sidebar;

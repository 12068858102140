export const percentage = 5;
export const discount = (price) => {
  if (percentage) {
    const cost =
      typeof price === "number"
        ? (price / 100) * percentage
        : (parseInt(price) / 100) * percentage;
    return price - cost;
  }
  return price;
};

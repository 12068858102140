import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import decodeToken from "../utils/decodeToken";
import { logoutUser } from "../redux/actions/authAction";
import { useStateContext } from "../libs/context";

export const RequireCardAuth = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const decodedToken = decodeToken(user.access_token);
  
  if (!localStorage.getItem('axao'))
    return <Navigate to="/activateaccount/login" state={{ from: location }} />;

  // if (!decodedToken || decodedToken.exp * 1000 < Date.now()) {
  //   dispatch(logoutUser());
  //   return <Navigate to="/activateaccount/login" state={{ from: location }} />;
  // }

  // if (
  //   !user?.has_card &&
  //   location.pathname !== "/activateaccount/validate-card"
  // ) {
  //   return <Navigate to="/activateaccount/validate-card" />;
  // }
  return children;
};

RequireCardAuth.propTypes = {
  children: PropTypes.element,
};

import React, { useState } from "react";
import "./mobileSidebar.css";
import rightArrow from "./icon/arrow_right.svg";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.svg";

export default function MobileSidebar({ openSidebar, setOpenSidebar }) {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  const DropdownContent = ({ array, heading }) => (
    <div className="dropdown-content">
      <p className="heading">{heading}</p>
      {activeDropdown === "products" && (
        <NavLink to="/products/buds">
          <p className="dropdown-list" onClick={() => setOpenSidebar(false)}>
            {" "}
            Onyx Panther{" "}
          </p>
        </NavLink>
      )}

      {array.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  );
  DropdownContent.propTypes = {
    array: PropTypes.arrayOf(PropTypes.string).isRequired,
    heading: PropTypes.string.isRequired,
  };

  return (
    <section className="mobile-sidebar">
      {/* <!-- mobile navigation --> */}
      <div className={`mobile__nav ${openSidebar && "active"}`}>
        <div className="navContents">
          <div className="navContents__texts">
            {/* dropdown1 */}
            <div className="mobile__dropdown">
              <p
                className="navContents__texts__dropdown"
                onClick={() => toggleDropdown("products")}
              >
                <span className="text-[#a8a7a7] tab-list">Products</span>
                <img
                  src={rightArrow}
                  className={`right-arrow ${
                    activeDropdown === "products" && "right-arrow--active"
                  }`}
                  alt=""
                />
              </p>
              {activeDropdown === "products" && (
                <DropdownContent
                  array={["Black Charger", "Bold Pro", "Cards", "Cables"]}
                  heading="Explore All Products"
                />
              )}
            </div>
            {/* dropdown2 */}
            <div className="mobile__dropdown">
              <p
                className="navContents__texts__dropdown"
                onClick={() => toggleDropdown("technology")}
              >
                <span className="text-[#a8a7a7] tab-list">Technology</span>
                <img
                  src={rightArrow}
                  className={`right-arrow ${
                    activeDropdown === "technology" && "right-arrow--active"
                  }`}
                  alt=""
                />
              </p>
              {activeDropdown === "technology" && (
                <DropdownContent
                  array={[
                    "Contactless Payment",
                    "Black Charger",
                    "Cards",
                    "Cables",
                  ]}
                  heading="Latest  AXAO Technology"
                />
              )}
            </div>
            <NavLink to="/products/store">
              <p
                className="tab-list text-[#a8a7a7] "
                onClick={() => setOpenSidebar(false)}
              >
                Our Store
              </p>
            </NavLink>
            <p
              className="tab-list text-[#a8a7a7] "
              onClick={() => setOpenSidebar(false)}
            >
              AXAO for Business
            </p>
          </div>
        </div>

        {/* Icons */}
        <section className="mobile_icons-container">
          <div className="search-icon">
            <img src={searchIcon} alt="" />
          </div>
          {/* <!--Hamburger--> */}
          <div
            className="hamburger"
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <div className="ham-container">
              <span></span>
            </div>
            <div className="ham-container">
              <span></span>
            </div>
            <div className="ham-container">
              <span></span>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- mobile navigation ends --> */}
    </section>
  );
}

MobileSidebar.propTypes = {
  openSidebar: PropTypes.bool.isRequired,
  setOpenSidebar: PropTypes.func.isRequired,
};

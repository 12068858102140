import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import thunkMiddleware from "redux-thunk";

import { rootReducer } from "./rootReducer";

// import { migrations } from "./migrations"



const storage = require("redux-persist/lib/storage").default;
const persistConfig = {
  key: "axao",
  blacklist: ["Layout"],
  storage,
//   migrate: createMigrate(migrations, { debug: false }),
};

const initStore = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);



initStore.__PERSISTOR = persistStore(initStore);
export const store = initStore;

import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from "../types";

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (errMessage) => ({
  type: LOGIN_FAILURE,
  payload: errMessage,
});

export const getUserData = (user, history) => (dispatch) => {
  axios
    .get(`/users/${user.id}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((res) => {
      dispatch(loginSuccess(res.data));
      if (history) {
        history.push("/onboarding");
      }
    })
    .catch((err) => console.log(err));
};

export const refreshToken = () => {
  
}


export const logoutUser = () => (dispatch) => {
  dispatch({ type: "LOG_OUT" });
};

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (errMessage) => ({
  type: REGISTER_FAILURE,
  payload: errMessage,
});
